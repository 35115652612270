export const en = {
	intro_title1:
		"The most successful platform is your best source for studying and preparing measurement tests,",
	intro_title2: "we make the successful",
	verbal_book_name: "Verbal Book",
	verbal_book_desc:
		"The most successful book for the verbal section is the most powerful book to train you on advanced methods and techniques that qualify you to get a very high score in the test of the measurement center",
	verbal_section_book: "Verbal Section Book",
	quantitative_book_name: "Quantitative Book",
	quantitative_book_desc:
		"The most successful book for the quantitative section is an explanation of the arithmetic and mathematical fundamentals comprehensive and familiar with mathematical sciences in its various sections, as it is considered a guide for beginners and also for obtaining a high score in aptitude tests Measurement Center",
	quantitative_section_book: "Quantitative Section Book",
	buy_book: "Download the book now",
	Includes: "Includes",
	Sections: "sections",
	Lecture: "lecture",
	Solve: "Solve",
	Test: "Test",
	Tests: "Tests",
	Cooking: "Cooking",
	Dr: "Dr.",
	Subscribe_Now: "subscribe now",
	Riyal: "SAR",
	Quantitative: "Quantitative",
	Verbal: "Verbal",
	Comprehensive_Course: "Comprehensive Course",
	Lectures: "Lectures",
	Questions_Answers: "Questions & Answers",
	Auditions: "auditions",
	Exam: "Exam",
	Lessons: "Lessons",
	Login_First: "Login First",
	Enter_Discount_Code: "Enter Discount Code",
	Activate_Code: "Activate Code",
};

export const ar = {
	intro_title1: "منصه انجح مصدرك الأفضل لمذاكرة وتحضير اختبارات القياس،",
	intro_title2: "انجح نصنع الناجحين",
	verbal_book_name: "الكتاب اللفظى",
	verbal_book_desc:
		"كتاب انجح  للقسم اللفظي اقوي كتاب لتدريبك   علي طرق وفنيات متقدمة تؤهلك للحصول على درجة عالية جدا في اختبار مركز قياس",
	verbal_section_book: "كتاب القسم اللفظي",
	quantitative_book_name: "الكتاب الكمي",
	quantitative_book_desc:
		"كتاب انجح للقسم الكمي عبارة عن شرح للآساسيات الحسابية والرياضية شامل وملم بالعلوم الرياضية بمختلف اقسامها حيث يعتبر دليل للمبتدئين ولحصولك ايضا علي درجه عاليه في اختبارات القدرات مركز قياس",
	quantitative_section_book: "كتاب القسم الكمي",
	buy_book: "تحميل الكتاب الآن",
	Includes: "يشمل",
	Sections: "اقسام",
	Lecture: "دروس",
	Solve: "حل",
	Test: "اختبار",
	Tests: "اختبارات",
	Cooking: "إعداد",
	Dr: "د/",
	Subscribe_Now: "اشترك الان",
	Riyal: "ريال",
	Quantitative: "كمي",
	Verbal: "لفظي",
	Comprehensive_Course: "دروس تعليمية شاملة",
	Lectures: "الدروس",
	Questions_Answers: "اسئلة واجوبة",
	Auditions: "الاختبارات",
	Exam: "امتحان",
	Lessons: "دروس",
	Login_First: "قم بتسجيل الدخول اولا",
	Enter_Discount_Code: "أدخل كود الخصم",
	Activate_Code: "تفعيل الكود",
};
