export const en = {
	Subscribe: "Subscribe",
	Injaah: "Injaah",
	Around: "around",
	About_Us: "About Us",
	Links: "Links",
	News_Blogs: "News & Blogs",
	Support: "Support",
	Help_Center: "Help Center",
	Terms_Conditions: "Terms and Conditions",
	Questions_Answers: "Questions & Answers",
	privacy_policy: "privacy policy",
	Be_Teacher: "Be a teacher",
	Partners: "Partners",
	Careers: "Careers",
	Footer_Desc1: `
        - The strongest accredited capabilities course in the Kingdom of Saudi Arabia
    `,
	Footer_Desc2:
		"- The most successful training platform aims to facilitate the educational process for the student and present it in a modern way that helps you quickly access the educational content and explain it in videos in a simplified way by qualified trainers",
	Rights_Reserved: "All rights reserved to",
	Welcome: "Welcome",
	Login: "Login",
	Registration: "Registration",
	Welcome_Back: "Welcome Back",
	Welcome_Back_Continue: "welcome back! Log in to continue.",
	Phone_Number: "Phone Number",
	Password: "Password",
	Enter_Code: "Enter the 4-digit code",
	Repeat_Password: "Repeat Password",
	Send_Reset_Code: "Send reset code",
	Again_After: "again after",
	Confirm_Code: "Confirm code",
	Create_New_Account: "Create a new account",
	Registeration_Free: "Registration is free and only takes a minute.",
	Full_Name: "Full Name",
	Name_Required: "Name Required",
	Phone_Required: "Phone Required",
	Password_Required: "Password Required",
	Re_Password_Required: "Re Password Required",
	Password_Match: "Password Must Match",
	Phone: "Phone",
	Contact_Us: "Contact Us",
	Solved: "Solved",
	Technical_Support: "Technical Support",
	Complaints_And_Suggestions: "Complaints and suggestions",
	Our_Team: "Our Team",
	CopyRights: "Intellectual property rights and copyrights",
	Instructions: "Instructions",
	Selecting_Teachers_Policy: "Policy for selecting teachers",
	About_Teachers: "About Teachers",
};

export const ar = {
	Subscribe: "اشترك",
	Injaah: "انجح",
	Around: "حول",
	About_Us: "معلومات عنا",
	Links: "الروابط",
	News_Blogs: "الأخبار والمدونات",
	Support: "الدعم",
	Help_Center: "مركز المساعدة",
	Terms_Conditions: "الأحكام والشروط",
	Questions_Answers: "الاسئلة والاجوبة",
	privacy_policy: "سياسة الخصوصية",
	Be_Teacher: "كن مدرسًا",
	Partners: "شركاء",
	Careers: "الوظائف",
	Footer_Desc1: `
        - اقوي  دروس تعليمية معتمدة على مستوى المملكة العربيه السعوديه
    `,
	Footer_Desc2:
		"- تهدف منصه انجح للتدريب إلى تسهيل العملية التعليمية للطالب وتقديمها بطريقة حديثه تساعدك علي  سرعة الوصول للمحتوى التعليمي وشرحه في فيديوهات بطريقة مبسطه على يد مدربين اكفاء",
	Rights_Reserved: "جميع الحقوق محفوظة لـ",
	Welcome: "مرحبًا",
	Login: "تسجيل دخول",
	Registration: "تسجيل جديد",
	Welcome_Back: "مرحبًا بعودتك",
	Welcome_Back_Continue: "مرحبًا بعودتك! سجل دخولك للمتابعة.",
	Phone_Number: "رقم الهاتف",
	Password: "كلمة المرور",
	Enter_Code: "أدخل الرمز المكون من 4 أرقام",
	Repeat_Password: "تكرار كلمة المرور",
	Send_Reset_Code: "إرسال رمز إعادة التعيين",
	Again_After: "مرة أخرى بعد",
	Confirm_Code: "رمز التأكيد",
	Create_New_Account: "انشاء حساب جديد",
	Registeration_Free: "التسجيل مجاني ولا يستغرق سوى دقيقة واحدة.",
	Full_Name: "الاسم بالكامل",
	Name_Required: "الاسم مطلوب",
	Phone_Required: "رقم الهاتف مطلوب",
	Password_Required: "كلمة المرور مطلوبة",
	Re_Password_Required: "إعادة كلمة المرور مطلوبة",
	Password_Match: "كلمة المرور يجب ان تتطابق",
	Phone: "هاتف",
	Contact_Us: "تواصل معنا",
	Solved: "تم حل",
	Technical_Support: "الدعم الفني",
	Complaints_And_Suggestions: "الشكاوى والمقترحات",
	Our_Team: "فريق العمل",
	CopyRights: "حقوق الملكية الفكرية وحقوق النشر",
	Instructions: "الإرشادات",
	Selecting_Teachers_Policy: "وجود سياسه واضحه لاختيار المعلمين",
	About_Teachers: "عن مدربينا",
};
